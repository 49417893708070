import logoLink from '../resources/aerztefon.png';
import companyLogoLink from '../resources/aerztefon.png';
import footerLogoLink from '../resources/aerztefon_emeye-t-inside_logo.svg';

export const cssVariables = {
    header_footer_seperator: '#D8011E',
    panel_highlight: '#D8011E',
    chat_background_right: '#F7CCD2',
    chat_background_left: '#D5D5D5',

    active: '#D8011E',
    inactive: '#535557',
    text_on_active: '#fff',
    text_on_inactive: '#fff',

    dropdown_background: '#F7CCD2',

    phonebook_dropdown: '#F7CCD2',
    phonebook_dropdown_svg: '#000',

    primary_light: '#F7CCD2',
    primary: '#D8011E',
    text_on_primary: '#fff',
    text_on_primary_caller: 'rgb(255, 255, 255)',

    secondary: '#535557',
    text_on_secondary: 'white',
    focus_active: '#D8011E',

    red: '#D8011E',
    green: '#3AC13A',

    connecting: '#535557',
    connected: '#3AC13A',
    aborted: '#D8011E',

    font_color: '#2C2A29',
    link_color: '#D8011E',

    font: 'Arial, Helvetica, sans-serif',
    font_size: '16px',
    font_weight: '400',

    border_radius: '20px',
    border_width: '2px',
    border_style: 'solid',
    border_radius_narrow: '10px',
    border_radius_super_narrow: '5px',
    border_width_nav: '8px',

    disabled: '#E2E1DE',

    gridbox_header_bg: '#535557',
    gridbox_header_text: '#fff',
    gridbox_padding: '8px',
    gridbox_header_padding: '8px',

    pointer: '#D8011E',

    toggle_switch: 'rgb(255, 255, 255)',
};

export const logo = logoLink;
export const companyTitle = 'Aerztefon';
export const companyLogo = companyLogoLink;
export const footerLogo = footerLogoLink;
